export const columns = [
	{
		prop: 'carNumber',
		label: '车牌号',
	},
	{
		prop: 'repairsTime',
		label: '车辆维修时间',
	},
	{
		prop: 'repairsDays',
		label: '已维修天数',
	},
	{
		prop: 'maintenanceId',
		label: '关联维保单',
	},
	{
		prop: 'companyName',
		label: '所属公司',
	},
	{
		prop: 'driverName',
		label: '司机姓名',
	},
	{
		prop: 'driverPhone',
		label: '司机手机号',
	},
	{
		prop: 'driverSupportName',
		label: '司服伙伴',
	},
	{
		prop: 'maintenanceManName',
		label: '维保专员',
	},
]

export const filters = {
	expand: true,
	filters: [
		{
			label: '司机信息',
			attr: 'keyword',
			type: 'search',
			placeholder: '输入司机姓名手机号搜索',
			clearable: true,
		},
		{
			attr: 'driverSupportIds',
			label: '司服伙伴',
			type: 'multi-select',
			placeholder: '请选择司服伙伴',
		},
		{
			attr: 'maintenanceManIds',
			label: '维保专员',
			type: 'multi-select',
			placeholder: '请选择维保专员',
		},
		{
			attr: 'repairsTimes',
			label: '维修时间',
			type: 'date',
			placeholder: '请选择维修时间',
		},
		{
			placeholder: '请选择所属公司',
			label: '所属公司',
			attr: 'companyIds',
			type: 'multi-select',
			options: [],
			width: 358,
		},
	],
}

export const rules = {}
