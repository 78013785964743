<template>
	<div class="page-container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">{{ pageTitle }}</div>
				<div class="header-button">
					<el-button
						v-permission="'pc/risk-center/overtime-repairs-warning/export'"
						@click="onExport"
						:loading="exportLoading"
					>
						导出Excel
					</el-button>
				</div>
			</template>
			<template #filter>
				<yi-filter ref="filterRef" :setting="filterSetting" @change="onChangeFilter" />
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					show-no
					:columns="columnSetting"
					:data="tableData"
					:params="{ page }"
				>
					<template #maintenanceId="{ row }">
						<el-link
							type="primary"
							style="margin-right: 15px"
							:underline="false"
							@click="onClick(row)"
						>
							查看
						</el-link>
					</template>
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
	</div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'OverTime',
})
</script>

<script setup name="OverTime">
import Request from '@/http'
import { inject, ref, getCurrentInstance, onMounted } from 'vue'
import { useFilter } from '@/utils/hooks.js'
import { filters, columns } from './config'
import { useRouter } from 'vue-router'
import usePage from '@/hooks/usePage'
import { getStore } from '@/utils/store'
import { handleExport } from '@/utils/util'
import { maintenanceSelect, exportOvertimeRepairs, companySelect } from '@/api/vehicle.js'

const userInfo = getStore({ name: 'userInfo' })
const pageTitle = '超时维修预警'

const instance = getCurrentInstance()
const router = useRouter()
const $message = inject('$message')

const filterSetting = ref(filters)
const columnSetting = ref(columns)
const exportLoading = ref(false)

const {
	isLoading,
	filterParams,
	totalCount,
	page,
	tableData,
	onRefreshData,
	onChangeFilter,
	onSizeChange,
	onPageChange,
} = usePage(queryList)

function queryList() {
	isLoading.value = true
	const params = {
		...filterParams.value,
		page: page.value,
	}
	if (params.deferredDays === null) {
		delete params.deferredDays
	}
	Request({
		url: 'suf4-risk-service/overtimeRepairs/paginate',
		method: 'POST',
		data: params,
	})
		.then(res => {
			if (res.code === 200) {
				isLoading.value = false
				tableData.value = res.data.records
				totalCount.value = res.data.total
				instance.refs.yiTableRef.scrollTop()
				console.log('列表获取成功 ==>', tableData)
			} else {
				isLoading.value = false
				$message.error(res.msg)
			}
		})
		.catch(error => {
			isLoading.value = false
			$message.error(error.message)
		})
}

function onClick(row) {
	const { carNumber } = row
	console.log('click')
	router.push({ path: '/maintenance-management', query: { keyword: carNumber, statuses: 3 } })
}
function findItem(key) {
	return filterSetting.value.filters.find(item => item.attr === key)
}
async function onExport() {
	exportLoading.value = true
	const params = {
		...filterParams.value,
		page: page.value,
	}
	try {
		const res = await exportOvertimeRepairs(params)
		if (res) handleExport(res, '超时维修预警')
	} catch (err) {
		console.log('err', err)
		$message.error(err.msg || err.message)
	} finally {
		exportLoading.value = false
	}
}
async function fetchFilter() {
	const { companyId } = userInfo
	const arr = companyId ? companyId.split(',') : []
	const [maintainRes, serverRes, companyRes] = await Promise.all([
		useFilter(() => maintenanceSelect('Mainten', arr, { isAll: true }), {
			name: 'realName',
			code: 'id',
		}),
		useFilter(() => maintenanceSelect('DriverService', arr, { isAll: true }), {
			name: 'realName',
			code: 'id',
		}),
		useFilter(() => companySelect(true), { name: 'companyName', code: 'companyId' }),
	])
	if (!maintainRes.err) {
		const target = findItem('maintenanceManIds')
		target.options = maintainRes.data
	}
	if (!serverRes.err) {
		const target = findItem('driverSupportIds')
		target.options = serverRes.data
	}
	if (!companyRes.err) {
		const company = findItem('companyIds')
		company.options = companyRes.data
	}
}

function initPage() {
	onRefreshData()
}

onMounted(async () => {
	fetchFilter()
	initPage()
})
</script>

<style lang="less" scoped>
.page-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.icon-plus {
		margin-right: 5px;
	}
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
}
</style>
